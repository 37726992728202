import React from "react";
import Helmet from "react-helmet";
import { useHomeContent } from "../../hooks/use-home-content";

/**
 * Head component
 *
 * @param {Object} props
 */
function Head({ context }) {
  const {
    data: {
      site_title,
      description,
      site_url,
      site_image,
      inactive_tab_messages,
    },
  } = useHomeContent();

  const handleVisibility = (e) => {
    if (typeof document === "undefined") return {};
    if (document.hidden) {
      let message =
        inactive_tab_messages[
          Math.floor(Math.random() * inactive_tab_messages.length)
        ];
      document.title = message.text;
    } else {
      document.title = site_title;
    }
  };

  React.useEffect(() => {
    if (typeof document === "undefined") return {};

    document.addEventListener("visibilitychange", handleVisibility);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  });

  return (
    <Helmet
      title={site_title}
      meta={[
        // Basics
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=yes",
        },
        { name: "title", content: site_title },
        { name: "description", content: description },
        // Open Graph / Facebook
        { property: "og:type", content: "website" },
        { property: "og:url", content: site_url },
        { property: "og:description", content: description },
        { property: "og:image", content: site_image.url },
        // Twitter
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: site_url },
        { property: "twitter:description", content: description },
        { property: "twitter:image", content: site_image.url },
      ]}
    />
  );
}

export default Head;
