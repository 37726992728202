import React from "react";
import styled from "styled-components";
import { m, LazyMotion, domAnimation } from "framer-motion";

/**
 * Local
 */
import Link from "../helpers/link";
import Logo from "../logo";

/**
 * Data
 */
import { animation } from "../../style/theme.js";
import NavFilterBar from "./nav-filter-bar";
import useHomeContent from "../../hooks/use-home-content";
import LinkExternal from "../helpers/link-external";
import InfoBar from "./nav-info-bar";
import { FilterContext } from "../../gatsby-plugin-layouts/layout";
import { rearrangeLettersRandom } from "../../functions/util";

/**
 * Nav component
 */
export default function Nav() {
  const {
    data: {
      bio_color,
      bio,
      contact_color,
      contact_links,
      filters,
      shops_nav_link,
      shops_nav_text,
    },
  } = useHomeContent();

  const [showFilters, setShowFilters] = React.useState(false);
  const [shuffleWord, setShuffleWord] = React.useState("Shuffle");
  const [showInfo, setShowInfo] = React.useState(false);
  const [showContact, setShowContact] = React.useState(false);

  const wrapperVariants = {
    pre: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 2,
        ease: animation.timingFunction.js,
        duration: 0.8,
      },
    },
  };

  const handleShuffle = ({ setShuffle, shuffle }) => {
    setShuffle(!shuffle);
    setShuffleWord(rearrangeLettersRandom("Shuffle"));
    setTimeout(() => {
      setShuffleWord("Shuffle");
    }, 1000);
  };

  const handleMenus = ([state, set], [otherState, otherSet]) => {
    if (showFilters) {
      setShowFilters(false);
    }
    set(!state);
    if (otherState) otherSet(false);
  };

  return (
    <LazyMotion features={domAnimation}>
      <NavWrapper
        id="top"
        initial="pre"
        animate="visible"
        variants={wrapperVariants}
        transition={{ ease: animation.timingFunction.js, duration: 0.8 }}
      >
        <NavInner>
          <NavLinksWrapper className="nav-left">
            <button
              className="button"
              onClick={() => setShowFilters(!showFilters)}
            >
              Filter
            </button>
            <FilterContext.Consumer>
              {({ shuffle, setShuffle }) => (
                <button
                  className="button"
                  onClick={() => handleShuffle({ setShuffle, shuffle })}
                >
                  {shuffleWord}
                </button>
              )}
            </FilterContext.Consumer>
          </NavLinksWrapper>

          <span className="nav-logo">
            <Link to="/">
              <Logo />
            </Link>
          </span>

          <NavLinksWrapper className="nav-right">
            <button
              className="button"
              data-active={showInfo}
              onClick={() =>
                handleMenus(
                  [showInfo, setShowInfo],
                  [showContact, setShowContact]
                )
              }
            >
              Info
            </button>
            <button
              className="button"
              data-active={showContact}
              onClick={() =>
                handleMenus(
                  [showContact, setShowContact],
                  [showInfo, setShowInfo]
                )
              }
            >
              Contact
            </button>
            {shops_nav_link && shops_nav_text && (
              <LinkExternal className="button" href={shops_nav_link.url} blank>
                {shops_nav_text}
              </LinkExternal>
            )}
          </NavLinksWrapper>
        </NavInner>
        <NavFilterBar showFilters={showFilters} filters={filters} />
        <InfoBar
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          bio_color={bio_color}
          bio={bio}
          showContact={showContact}
          setShowContact={setShowContact}
          contact_color={contact_color}
          contact_links={contact_links}
        />
      </NavWrapper>
    </LazyMotion>
  );
}

const NavWrapper = styled(m.nav)`
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;

  .nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:after {
    content: "";
    position: absolute;
  }

  &:after {
    bottom: calc(var(--pad) * -1);
    left: 0;
    width: 100%;
    height: var(--pad);
    background: var(--h);
    z-index: 6;
  }
`;

const NavInner = styled(m.div)`
  display: grid;
  grid-template-columns: 1fr 50% 0px 50% 1fr;
  grid-template-rows: 3.4rem 3.4rem;
  grid-template-areas:
    ". . logo . ."
    ". filter . info .";
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 4rem;
  background-color: var(--light);
  padding: 0 ${({ theme }) => theme.layout.outerWrapperMobile};

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 200px 1fr 200px;
    grid-template-rows: 4rem;
    grid-template-areas: "filter logo info";
    padding: 0 ${({ theme }) => theme.layout.outerWrapper};
  }

  .nav-logo {
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NavLinksWrapper = styled.div`
  display: grid;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  button,
  a {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.85rem;
    text-transform: uppercase;
    color: var(--dark);
    appearance: none;
    letter-spacing: 0.1em;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;

    &[data-active="true"] {
      pointer-events: none;
      cursor: pointer;
    }
  }

  &.nav-left {
    grid-area: filter;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.nav-right {
    grid-area: info;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
