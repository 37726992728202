import React from "react";
import styled from "styled-components";
import { m, domAnimation, LazyMotion } from "framer-motion";

import { Wrapper } from "./wrappers";
import { useOutsideAlerter } from "../../functions/util";
import { Button } from "./button";

export const NavInfoBar = styled(m.div)`
  color: var(--light);
  background: var(--dark);
  overflow: hidden;

  .bio {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${({ theme }) => theme.layout.space[200]}
      ${({ theme }) => theme.layout.outerWrapperMobile};

    @media ${({ theme }) => theme.device.tablet} {
      padding: ${({ theme }) => theme.layout.space[200]}
        ${({ theme }) => theme.layout.outerWrapper};
    }

    p,
    .h1,
    .h4 {
      max-width: 15em;
    }
  }

  .contact {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.5vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${({ theme }) => theme.layout.space[200]}
      ${({ theme }) => theme.layout.outerWrapperMobile};

    @media ${({ theme }) => theme.device.tablet} {
      grid-auto-flow: column;
      padding: ${({ theme }) => theme.layout.space[200]}
        ${({ theme }) => theme.layout.outerWrapper};
    }

    p,
    a {
      margin-bottom: 0;
      text-decoration: none;
    }
  }
`;

export default function InfoBar({
  showInfo,
  setShowInfo,
  bio_color,
  bio,
  showContact,
  setShowContact,
  contact_color,
  contact_links,
}) {
  const infoRef = React.useRef();
  const contactRef = React.useRef();
  useOutsideAlerter(infoRef, setShowInfo, false, "info");
  useOutsideAlerter(contactRef, setShowContact, false, "contact");

  const infoBarVariants = {
    closed: {
      height: 0,
      transition: { duration: 0.4 },
    },
    open: {
      height: "auto",
      transition: { type: "spring", damping: 25, stiffness: 250 },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <NavInfoBar
        ref={infoRef}
        variants={infoBarVariants}
        transition
        initial="closed"
        animate={showInfo ? "open" : "closed"}
        color={bio_color}
      >
        <Wrapper className="bio">
          <h4 className="h1">{bio.text}</h4>
        </Wrapper>
      </NavInfoBar>
      <NavInfoBar
        ref={contactRef}
        variants={infoBarVariants}
        initial="closed"
        animate={showContact ? "open" : "closed"}
        color={contact_color}
      >
        <Wrapper className="contact">
          {contact_links.map(({ link, link_text }, i) => {
            return (
              <Button
                key={i}
                href={link.url}
                aria-hidden={showContact ? false : true}
                tabIndex={showContact ? 0 : -1}
                data-large={true}
                data-invert={true}
                blank
              >
                <p className="h4">{link_text}</p>
              </Button>
            );
          })}
        </Wrapper>
      </NavInfoBar>
    </LazyMotion>
  );
}
