import React from "react";
import { FilterContext } from "../../gatsby-plugin-layouts/layout";
import { Wrapper } from "./wrappers";
import styled from "styled-components";
import { m, LazyMotion, domAnimation } from "framer-motion";
import Link from "../helpers/link";

const filterBarVariants = {
  closed: {
    height: 0,
    transition: { duration: 0.4 },
  },
  open: {
    height: "auto",
    transition: { type: "spring", damping: 25, stiffness: 250 },
  },
};

const FilterBar = styled(m.div)`
  background: var(--dark);
  color: var(--light);
  overflow: hidden;
`;

const TagWrapper = styled(Wrapper)`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 1rem;
  height: 3.4rem;
  align-items: center;
  justify-content: start;
  padding: 0 ${({ theme }) => theme.layout.outerWrapperMobile};
  border-top: 1px solid var(--dark);
  overflow-x: scroll;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 ${({ theme }) => theme.layout.outerWrapper};
    overflow: hidden;
  }

  a {
    color: var(--light);
  }

  button,
  a {
    text-transform: uppercase;
    appearance: none;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    border: 0;
    background: transparent;
    cursor: pointer;
    white-space: pre;
  }
`;

const TagItem = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 3.4rem;
  padding: 0rem 0.5rem;
  text-decoration: none;
  transition: transform ${({ theme }) => theme.animation.duration[300].css},
    opacity ${({ theme }) => theme.animation.duration[100].css},
    padding ${({ theme }) => theme.animation.duration[100].css},
    background ${({ theme }) => theme.animation.duration[100].css} !important;
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};

  &:hover {
    transform: translateY(-0.1em);
  }

  &:active {
    transform: translateY(0.1em);
  }

  &[data-active="true"] {
    opacity: 1;
    background: var(--light);
    color: var(--dark);
    padding: 0rem 1.5rem;

    &:hover,
    &:active {
      transform: translateY(0em);
    }
  }

  .subscript {
    position: absolute;
    left: calc(100% + 0.5em);
    bottom: -0.1em;
  }
`;

function NavFilterBar({ showFilters, filters }) {
  return (
    <LazyMotion features={domAnimation}>
      <FilterContext.Consumer>
        {({ showGroup }) => {
          return (
            <FilterBar
              variants={filterBarVariants}
              initial="closed"
              animate={showFilters ? "open" : "closed"}
            >
              <TagWrapper className="inner">
                <TagItem
                  data-active={"all" === showGroup}
                  to={`/?tag=all`}
                  aria-hidden={showFilters ? false : true}
                  tabIndex={showFilters ? 0 : -1}
                >
                  All
                </TagItem>
                {filters.map(({ tag }, i) => {
                  const active = tag === showGroup;
                  return (
                    <TagItem
                      key={i}
                      data-active={active}
                      to={`/?tag=${tag}`}
                      aria-hidden={showFilters ? false : true}
                      tabIndex={showFilters ? 0 : -1}
                    >
                      {tag}
                    </TagItem>
                  );
                })}
              </TagWrapper>
            </FilterBar>
          );
        }}
      </FilterContext.Consumer>
    </LazyMotion>
  );
}

export default NavFilterBar;
