import React from "react";
import styled from "styled-components";

/**
 *
 */
import LinkExternal from "../helpers/link-external";
import { siteMetadata } from "../../../gatsby-config";
import { Link } from "gatsby";
import { useHomeContent } from "../../hooks/use-home-content";

/**
 * Footer component
 *
 * @param {Object} props
 * @param {Object} props.context
 */
export default function Footer({ context }) {
  const {
    data: { scroll_up_text },
  } = useHomeContent();
  return (
    <>
      <CheckerBar className="check-h" />
      <FooterContainer id="footer" data-page={context.uid}>
        <FooterRow className="links">
          <Link to="#top">{scroll_up_text}</Link>
          <LegalWrapper>
            © Copyright 2021 {siteMetadata.author}.
            <>
              {" "}
              Site by{" "}
              <LinkExternal href="https://john.design" blank>
                John Choura
              </LinkExternal>
              .
            </>
          </LegalWrapper>
        </FooterRow>
      </FooterContainer>
    </>
  );
}

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 ${({ theme }) => theme.layout.outerWrapperMobile};
  width: calc(100vw - (${({ theme }) => theme.layout.outerWrapperMobile} * 2));
  gap: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0 ${({ theme }) => theme.layout.outerWrapper};
    width: calc(100vw - (${({ theme }) => theme.layout.outerWrapper} * 2));
  }

  p,
  button,
  a {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.85rem;
    text-transform: uppercase;
    appearance: none;
    letter-spacing: 0.1em;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    text-decoration: none;
  }
`;

const CheckerBar = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--pad);
  background: var(--h);
  z-index: 6;
`;

const FooterContainer = styled.footer`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  padding: 1rem 0;
`;

const LegalWrapper = styled.p`
  margin: 0;
`;
