import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Svg = styled(motion.svg)`
  overflow: visible;
`;

function Logo(props) {
  const container = {
    hidden: { opacity: 0, scale: 0.75 },
    show: {
      opacity: 1,
      scale: 0.75,
      transition: {
        staggerChildren: 0.075,
        bounce: 0.2,
        stiffness: 10,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: [0, 1, 1, 1, 1, 1, 1],
      fill: "var(--dark)",
    },
  };

  return (
    <Svg
      width={198}
      height={45}
      viewBox="0 0 198 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      variants={container}
      initial="hidden"
      animate="show"
      {...props}
    >
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.14082 0C6.39099 0.465634 6.70591 0.908307 6.88079 1.40064C7.9601 4.43995 8.9939 7.49527 10.0733 10.5344C10.4932 11.7166 10.824 11.8187 11.8914 11.1842C12.9047 10.5818 13.8953 9.94145 14.904 9.33124C16.2178 8.53654 17.6354 8.00416 19.1715 7.92433C19.7964 7.89202 20.4684 8.00576 21.0581 8.22069C22.1768 8.62812 22.6018 9.66525 22.224 10.917C22.0915 11.3561 21.8613 11.7781 21.6132 12.1682C19.8077 15.007 17.8387 17.7261 15.5719 20.2176C14.2599 21.6596 14.2454 23.1553 15.0303 24.8918C16.2748 27.6448 17.4017 30.4542 18.4852 33.2755C19.0381 34.7151 19.217 36.2485 18.9715 37.8037C18.6618 39.7649 17.1643 40.4691 15.5275 39.3546C14.7233 38.807 13.9519 38.1796 13.2758 37.4818C11.6712 35.8254 9.95641 34.2531 8.88817 32.1449C8.67805 31.7306 8.30132 31.4004 7.8768 30.8782C7.61889 31.5718 7.39942 32.0738 7.2447 32.5948C6.80149 34.0869 6.40528 35.5929 5.94965 37.0808C5.66237 38.0193 5.11303 38.8352 4.08191 38.9615C2.98497 39.0959 2.28292 38.353 1.72424 37.4973C1.08172 36.5132 0.901366 35.3913 0.813125 34.2555C0.565089 31.0655 0.340816 27.8733 0.102925 24.6825C0.0867725 24.4634 0.035243 24.247 0 24.0293C0 20.9589 0 17.8885 0 14.8181C0.0391144 14.4899 0.0833016 14.1622 0.116809 13.8334C0.346022 11.5832 0.462163 9.31442 0.828477 7.08691C1.30906 4.16294 2.61265 1.66082 5.20635 0C5.51779 0 5.82937 0 6.14082 0Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.561 11.7062C62.2952 12.8512 62.0011 13.9904 61.7714 15.1426C61.5287 16.3607 61.3104 17.586 61.1493 18.8171C60.9804 20.1074 61.6044 21.0107 62.7427 21.5453C63.7882 22.0361 64.8976 22.3987 65.9164 22.9357C67.0901 23.5545 68.2531 24.2248 69.316 25.0139C70.7117 26.0501 71.0716 27.4462 70.237 28.9706C69.6069 30.1213 68.7499 31.1765 67.858 32.1496C64.9897 35.2789 62.0665 38.3585 59.1416 41.4355C58.7203 41.8786 58.2036 42.2548 57.674 42.5639C57.2031 42.8389 56.6172 43.109 56.1187 42.6618C55.5798 42.1787 55.1199 41.546 55.3616 40.782C55.9432 38.9423 56.5554 37.1089 57.2503 35.3096C57.8424 33.777 58.5962 32.3076 59.2245 30.7879C59.8725 29.2203 59.3914 28.4786 57.733 28.1528C55.7722 27.7675 53.8115 27.3585 51.8826 26.841C49.257 26.1367 48.2756 24.4035 48.6209 21.7048C48.9316 19.2764 50.2971 17.4274 51.6974 15.5907C53.6035 13.0904 55.9296 11.0406 58.643 9.43929C60.9859 8.05667 62.4023 8.83655 62.4809 11.5552C62.4821 11.5997 62.481 11.6441 62.481 11.6886C62.5076 11.6946 62.5343 11.7004 62.561 11.7062Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.0977 41.2497C86.2187 39.0748 86.8309 37.0985 87.4636 35.1301C88.1843 32.8876 88.9359 30.6733 90.2328 28.6551C91.6341 26.4742 91.4455 24.0989 90.6963 21.7096C89.7727 18.7639 88.912 15.7985 88.0131 12.8448C87.6159 11.5396 87.5689 10.2706 88.2559 9.03429C88.7002 8.23505 89.1005 8.04428 90.0063 8.24225C90.7958 8.41473 91.4763 8.76543 91.9294 9.45533C92.5144 10.3463 93.0827 11.2482 93.6463 12.1526C93.8221 12.435 93.9475 12.7484 94.1174 13.0348C94.7817 14.154 95.4939 14.2518 96.5081 13.4292C97.7848 12.3934 99.0689 11.3647 100.386 10.3818C101.462 9.57882 102.653 9.06392 104.052 9.30902C105.717 9.60071 106.437 10.4114 106.534 12.1018C106.596 13.1811 106.294 14.1633 105.772 15.0954C101.706 22.3473 97.6451 29.6024 93.5778 36.8537C92.4469 38.87 91.3143 40.8859 90.1518 42.8844C89.6755 43.7035 89.0942 44.4465 88.0197 44.5026C87.1241 44.5493 86.7101 44.2602 86.4469 43.3969C86.3628 43.1212 86.2916 42.8384 86.2492 42.554C86.1807 42.0931 86.1405 41.6282 86.0977 41.2497Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.5738 30.2048C83.5766 32.758 82.4989 33.7079 80.8112 33.2098C79.819 32.9169 78.7852 32.4132 78.0336 31.719C75.0612 28.9733 72.1878 26.1201 69.2907 23.2933C68.7345 22.7508 68.2146 22.1666 67.7161 21.5698C66.2911 19.8647 66.0978 17.9263 66.6903 15.8531C67.2205 13.9981 68.3048 12.4406 69.4139 10.9102C70.4051 9.54252 71.4485 8.20943 72.5261 6.90837C73.356 5.90662 74.4652 5.35501 75.7835 5.31657C77.0788 5.27879 77.8379 6.09458 77.6721 7.38642C77.5906 8.0212 77.4148 8.65197 77.2113 9.26111C76.7603 10.6109 76.235 11.9361 75.7949 13.2892C75.2266 15.0361 75.6355 15.5477 77.4825 15.6178C79.0368 15.6769 80.5903 15.7751 82.1406 15.9016C83.1837 15.9868 84.062 16.4559 84.6976 17.3013C85.5462 18.43 85.3783 19.8397 84.3102 20.7722C84.2098 20.8598 84.0995 20.9361 83.9975 21.0223C82.1987 22.5432 81.8616 23.8622 82.5369 26.1243C83.0115 27.7133 83.3574 29.3407 83.5738 30.2048Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6206 24.1412C18.6617 22.2714 19.3243 20.6593 20.7679 19.4965C22.9476 17.7412 25.1608 16.0159 27.4669 14.4337C28.5059 13.7208 29.7778 13.3094 30.9912 12.901C31.9179 12.589 32.8476 12.8141 33.6011 13.5016C34.318 14.1556 34.6787 14.9684 34.2992 15.8999C33.8987 16.8824 33.3717 17.8139 32.8937 18.7645C32.4652 19.6169 31.9926 20.4491 31.6052 21.3197C31.2277 22.1678 31.4183 22.9467 32.1531 23.5309C32.9172 24.1387 33.7334 24.6818 34.4913 25.2969C35.855 26.4037 35.8624 27.6112 34.4539 28.6417C33.6535 29.2273 32.7481 29.6733 31.8738 30.1526C31.4466 30.3868 30.9693 30.5285 30.5373 30.7554C29.1251 31.4966 28.9045 32.0705 29.4801 33.5633C30.0499 35.0408 30.3752 36.544 30.0598 38.1274C29.9995 38.4292 29.8985 38.7304 29.7685 39.0094C28.9317 40.805 27.2202 41.079 25.9357 39.5829C22.3984 35.463 19.8819 30.8156 18.7753 25.4606C18.6859 25.0286 18.6704 24.5814 18.6206 24.1412Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.9724 32.3461C36.9724 28.1022 36.9549 23.8584 36.9833 19.6148C36.9916 18.3892 37.208 17.1885 37.791 16.0722C38.365 14.973 39.2516 14.4038 40.4748 14.4415C41.6935 14.479 42.4664 15.2273 42.8263 16.3088C43.6823 18.8805 44.4621 21.4774 45.2672 24.0657C45.7292 25.5511 46.1647 27.045 46.6409 28.5258C47.1173 30.0072 47.5803 30.3139 49.15 30.4344C50.3661 30.5277 51.5865 30.6369 52.7857 30.8485C53.4496 30.9656 54.1081 31.2429 54.7074 31.5635C55.5647 32.0224 55.8075 32.8291 55.1858 33.5713C54.4854 34.4077 53.6741 35.2451 52.7364 35.7708C50.2974 37.1382 47.7852 38.3822 45.2588 39.5842C43.4754 40.4327 41.5345 40.5825 39.5817 40.4489C38.0013 40.3406 37.0162 39.2606 36.9729 37.5424C36.9293 35.8114 36.9633 34.0785 36.9633 32.3463C36.9663 32.3462 36.9693 32.3461 36.9724 32.3461Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144.219 42.6302C143.457 41.7534 143.29 40.6896 143.487 39.6209C144.431 34.4864 145.419 29.3602 146.41 24.2343C147.101 20.6562 147.825 17.0843 148.53 13.509C148.839 11.9393 150.02 10.8438 151.405 11.0075C151.962 11.0733 152.618 11.3338 152.988 11.7307C153.642 12.4327 154.293 13.238 154.627 14.1204C156.699 19.5853 158.86 25.0262 160.197 30.736C160.567 32.3119 160.818 33.918 161.063 35.5194C161.228 36.5956 160.989 37.556 159.952 38.1636C158.863 38.8019 157.782 38.7596 156.739 38.1001C156.313 37.8306 155.943 37.4624 155.577 37.1085C154.527 36.0926 153.676 36.0771 152.635 37.1059C151.514 38.2143 150.454 39.3893 149.281 40.4378C148.375 41.2468 147.335 41.9049 146.355 42.6301C145.643 42.6302 144.93 42.6302 144.219 42.6302Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M191.209 42.6302C189.565 41.1086 187.873 39.6348 186.287 38.0546C183.632 35.4076 181.338 32.4643 179.461 29.2106C178.789 28.0457 178.276 26.8159 178.234 25.4447C178.191 23.9914 178.714 22.7553 179.9 21.915C182.31 20.2084 184.742 18.5316 187.211 16.9121C188.384 16.1431 189.655 16.341 190.815 17.0245C191.582 17.4764 191.818 18.2969 191.532 19.0684C191.089 20.2664 190.502 21.4171 189.903 22.5489C189.52 23.272 188.983 23.912 188.553 24.6127C188.092 25.3647 188.27 25.7874 189.138 25.9301C190.672 26.1821 192.229 26.3111 193.749 26.6276C194.778 26.842 195.83 27.1588 196.743 27.6636C198.051 28.3871 198.085 29.6179 196.809 30.391C195.761 31.0257 194.58 31.4903 193.402 31.8496C191.43 32.4512 191.223 32.8062 192.085 34.6599C192.573 35.7075 193.028 36.7705 193.48 37.8337C193.636 38.2006 193.758 38.5848 193.865 38.9697C194.374 40.8011 194.086 41.5893 192.544 42.6304C192.099 42.6302 191.654 42.6302 191.209 42.6302Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.888 23.8693C143.61 24.8877 143.476 25.974 143.031 26.9134C140.824 31.5776 137.888 35.6954 133.619 38.7162C132.776 39.3121 131.829 39.7951 130.87 40.1809C129.799 40.612 128.807 40.2665 128.011 39.4489C126.926 38.3339 126.261 36.9616 125.949 35.4694C124.687 29.4218 123.469 23.3648 122.27 17.3042C122.021 16.0459 122.172 14.7738 122.569 13.5499C123.38 11.0519 125.5 9.80934 128.045 10.4426C129.113 10.7084 130.144 11.1657 131.153 11.6222C134.487 13.1309 137.72 14.8301 140.354 17.4277C141.325 18.3847 142.163 19.5005 142.928 20.6337C143.556 21.5645 143.787 22.6761 143.708 23.8238C143.768 23.8387 143.828 23.8541 143.888 23.8693Z"
        fill="black"
      />
      <motion.path
        variants={item}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M169.055 32.7895C168.88 33.2265 168.654 33.6511 168.543 34.1038C168.401 34.6841 168.375 35.2921 168.254 35.8789C168.019 37.0219 167.396 37.6236 166.424 37.6997C165.164 37.7983 164.352 37.371 163.989 36.2018C163.703 35.2766 163.57 34.2943 163.453 33.3274C162.863 28.4749 162.271 23.6226 161.755 18.7622C161.654 17.8114 161.77 16.798 161.991 15.8622C162.297 14.5697 163.314 14.173 164.524 14.7207C165.617 15.2149 166.4 16.0485 167.043 17.0349C167.319 17.4575 167.606 17.8726 167.943 18.373C168.16 18.1411 168.299 17.9809 168.45 17.8332C169.861 16.4516 171.232 15.0247 172.709 13.7167C173.265 13.2233 174.034 12.8774 174.765 12.6904C176.333 12.2889 177.66 13.3697 177.737 14.99C177.812 16.5711 177.155 17.9352 176.428 19.2703C176.056 19.9532 175.624 20.6028 175.248 21.284C173.665 24.1574 173.875 26.9658 175.648 29.6677C176.88 31.5446 177.517 33.6443 178.042 35.7833C178.361 37.0821 177.843 38.2023 176.821 38.7606C175.648 39.4019 174.493 39.3196 173.555 38.2443C172.479 37.0112 171.593 35.6133 170.604 34.3025C170.198 33.7647 169.739 33.2673 169.304 32.7518C169.221 32.7642 169.138 32.7769 169.055 32.7895Z"
        fill="black"
      />
    </Svg>
  );
}

export default Logo;
