import { useStaticQuery, graphql } from "gatsby";

export const useHomeContent = () => {
  const data = useStaticQuery(
    graphql`
      query queryHomepage {
        prismicHomepage {
          id
          data {
            site_url
            site_title
            short_name
            description
            site_image {
              url
            }
            inactive_tab_messages {
              text
            }
            scroll_up_text
            title
            bio_color
            bio {
              text
            }
            contact_color
            contact_links {
              link {
                url
                target
              }
              link_text
            }
            filters {
              tag
            }
            shops_nav_link {
              url
              target
            }
            shops_nav_text
          }
        }
      }
    `
  );

  return data.prismicHomepage;
};

export default useHomeContent;
