import { useStaticQuery, graphql } from "gatsby";

export const useTags = () => {
  const data = useStaticQuery(
    graphql`
      query tagsQuery {
        allPrismicWorkEnt {
          distinct(field: tags)
          group(field: tags) {
            edges {
              node {
                id
                uid
                type
                data {
                  title {
                    text
                    html
                    raw
                  }
                }
              }
            }
            field
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return data.allPrismicWorkEnt;
};

export default useTags;
