import { domAnimation, LazyMotion, m } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { getRandomInt } from "../../functions/util";
import Link from "../helpers/link";
import LinkExternal from "../helpers/link-external";

export function Button({
  children,
  text,
  onClick,
  href,
  to,
  icon,
  color,
  ...rest
}) {
  const rotationHover = getRandomInt(-10, 10);

  const motionProps = {
    initial: {
      rotate: 0,
    },
    whileHover: {
      rotate: rotationHover,
      scale: 1.1,
      transition: {
        duration: 0.2,
      },
    },
    transition: {
      duration: 0.2,
    },
  };

  const sharedProps = {
    className: "button",
    ...rest,
  };

  return (
    <LazyMotion features={domAnimation}>
      {onClick ? (
        <ButtonElement onClick={onClick} {...sharedProps}>
          <ElementInner color={color} {...motionProps}>
            {text || children}
          </ElementInner>
        </ButtonElement>
      ) : to ? (
        <LinkElement to={to} {...sharedProps}>
          <ElementInner color={color} {...motionProps}>
            {text || children}
          </ElementInner>
        </LinkElement>
      ) : href ? (
        <HrefElement href={href} {...sharedProps}>
          <ElementInner color={color} {...motionProps}>
            {text || children}
          </ElementInner>
        </HrefElement>
      ) : null}
    </LazyMotion>
  );
}

const ElementInner = styled(m.span)`
  display: inline-flex;
  color: ${({ color }) => color || `var(--light)`};
  background: var(--dark);
  padding: 0.45rem;
  cursor: pointer;
`;

const ButtonElement = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const LinkElement = styled(Link)``;

const HrefElement = styled(LinkExternal)`
  &[data-large="true"] ${ElementInner} {
    padding: 0.8rem 1.2rem;
  }
  &[data-invert="true"] ${ElementInner} {
    color: var(--dark);
    background: var(--light);
  }
`;
