import { useStaticQuery, graphql } from "gatsby";

export const useThemeData = () => {
  const data = useStaticQuery(
    graphql`
      query queryThemeData {
        prismicHomepage {
          id
          data {
            dark
            light
          }
        }
      }
    `
  );

  return data.prismicHomepage;
};

export default useThemeData;
