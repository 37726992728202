import { createGlobalStyle } from "styled-components";

const BaseStyles = createGlobalStyle`

  :root {
    --pad: 8px;

    --dark: ${({ theme }) => theme?.cmsColors?.dark || "black"};
    --light: ${({ theme }) => theme?.cmsColors?.light || "white"};


  }

  ::selection {
    background: var(--light);
  }

  html {
    scroll-behavior: smooth;
  }

  html {
    background-color: var(--light);
    font-size: 16px;
  }

  body {
    font-family: hobeaux, sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--light);
    color: var(--dark);
    fill: var(--dark);
    --v: url("/pattern-v.svg");
    --v-flip: url("/pattern-v-flip.svg");
    --h: url("/pattern-h.svg");
    --h-flip: url("/pattern-h-flip.svg");
  }

  * {
      box-sizing: border-box;
      transition-timing-function: ${({ theme }) =>
        theme.animation.timingFunction.css};
  }

  main#main {
    min-height: 100vh;
  }

  *:focus-visible {
    outline: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -3px;
      left: -3px;
      bottom: 0;
      right: 0;
      border: solid 3px var(--light);
      border-radius: 4px;
      z-index: 100;
    }
  }

  pre, code {
    font-weight: 400;
    margin: 1rem 0;
  }

  hr {
    border: solid 1.5px var(--light);
    background: var(--light);
    margin: 3rem 0;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    margin-left: -0.02em;
    font-weight: bold;
  }

  h1,
  .h1 {
    font-size: ${({ theme }) => theme.fonts.h1};
    line-height: 110%;
  }

  h2,
  .h2 {
    font-size: ${({ theme }) => theme.fonts.h2};
    line-height: 110%;
  }

  h3,
  .h3 {
    font-size: ${({ theme }) => theme.fonts.h3};
    line-height: 110%;
  }

  h4,
  .h4 {
    font-size: ${({ theme }) => theme.fonts.h4};
    line-height: 110%;
  }

  h5,
  .h5 {
    font-size: ${({ theme }) => theme.fonts.h5};
    line-height: 110%;
  }

  h6,
  .h6 {
    font-size: ${({ theme }) => theme.fonts.h6};
    line-height: 110%;
  }

  p,
  .p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 200%;

    &.strong {
      font-weight: 500;
    }
  }

  p.lead,
  .p-lead {
    font-size: 24px;
    line-height: 200%;
  }

  p.caption,
  .p-caption {
    font-size: 11px;
    line-height: 170%;
    letter-spacing: 0.01em;
  }

  a {
    color: var(--dark);
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
    opacity: 1;

    @media (hover: hover) {
      &:hover {
        opacity: .9;
      }
    }
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }





  @keyframes animate-h {
    from {
      background-position-x: 0px;
    }

    to {
      background-position-x: -108px;
    }
  }

  @keyframes animate-v {
    from {
      background-position-y: 0px;
    }

    to {
      background-position-y: -108px;
    }
  }

  body {
    &:before,
    > span:before,
    *:before, .check-v {
      animation-duration: 2s;
      animation-delay: 1s;
      animation-name: animate-v;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }

    &:after,
    > span:after,
    *:after, .check-h {
      animation-duration: 2s;
      animation-delay: 1s;
      animation-name: animate-h;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
  }
`;

export default BaseStyles;
