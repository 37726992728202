import styled from "styled-components";

export const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  min-height: 90vh;
`;

export const Wrapper = styled.section`
  padding: 0 ${({ theme }) => theme.layout.outerWrapperMobile};
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 ${({ theme }) => theme.layout.outerWrapper};
  }
`;
