import React from "react";

/**
 * Base Layout Component
 */
import Layout from "./layout";

/**
 * Base Styles
 */
import { ThemeProvider } from "styled-components";
import theme from "../style/theme";
import ResetStyles from "../style/reset-styles";
import BaseStyles from "../style/base-styles";
import "../style/fonts.css";
import useThemeData from "../hooks/use-theme-data";

const PageLayout = ({ children, pageContext, ...rest }) => {
  const {
    data: { dark, light },
  } = useThemeData();

  const cmsColors = { dark, light };

  return (
    <ThemeProvider theme={{ ...theme, cmsColors }}>
      <ResetStyles />
      <BaseStyles />
      <Layout pageContext={pageContext} {...rest}>
        {children}
      </Layout>
    </ThemeProvider>
  );
};

export const BrowserPageLayout = PageLayout;
export const SsrPageLayout = PageLayout;
