import React, { useState, createContext, useMemo } from "react";

/**
 * Local Components
 */
import Head from "../components/globals/head";
import Nav from "../components/globals/nav";
import Footer from "../components/globals/footer";

/**
 * Local styles
 */
import { PageWrapper } from "../components/globals/wrappers";
import { useTags } from "../hooks/use-tags";

/**
 * Context
 */
export const FilterContext = createContext(null);

/**
 * Layout Component
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Object} props.pageContext
 * @returns page layouts
 */
export default function Layout({ children, location: { search }, ...rest }) {
  const [showGroup, setShowGroup] = useState("all");
  const [shuffle, setShuffle] = useState(false);
  const { group } = useTags();
  const groupSet = [{ fieldValue: "all" }, ...group];

  const value = useMemo(
    () => ({ showGroup, setShowGroup, groupSet, shuffle, setShuffle }),
    [showGroup, groupSet, shuffle]
  );

  React.useEffect(() => {
    if (search.includes("?tag=")) {
      const cleanLocation = search.replace("?tag=", "").replace(/%20/g, " ");
      setShowGroup(cleanLocation);
    } else {
      setShowGroup("all");
    }
  }, [search]);

  return (
    <FilterContext.Provider value={value}>
      <Head context={rest} />
      <Nav context={rest} />
      <PageWrapper id="page">{children}</PageWrapper>
      <Footer context={rest} />
    </FilterContext.Provider>
  );
}
